/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "Noto Sans Thai", sans-serif;
    @apply bg-gray-100;
}

.card {
    @apply w-full border bg-white rounded-lg p-4 lg:p-6 transition-opacity delay-300;
}

.h-content {
    height: calc(100vh - 3.5rem);
}

label {
    @apply block mb-1 font-medium text-gray-900;
}

.input-invalid {
    @apply flex w-full border !border-red-500 rounded-lg h-14 py-4 px-5 hover:ring-1 hover:ring-red-400 focus:ring-red-400 focus:outline outline-red-400;
}

.input {
    @apply bg-white border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 h-[46px] disabled:bg-gray-100 disabled:cursor-not-allowed placeholder:text-sm;
}

.text-area {
    @apply bg-white border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 min-h-[46px] disabled:bg-gray-100 disabled:cursor-not-allowed;
}

.btn-primary {
    @apply bg-yellow-500 px-4 py-2.5 rounded-lg h-[46px] disabled:opacity-60 disabled:cursor-not-allowed;
}

.btn-secondary {
    @apply bg-gray-600 px-4 py-2.5 rounded-lg text-white h-[46px] disabled:opacity-60 disabled:cursor-not-allowed;
}

.btn-danger {
    @apply bg-red-500 px-4 py-2.5 rounded-lg text-white h-[46px] disabled:opacity-60 disabled:cursor-not-allowed;
}

.btn-light {
    @apply bg-white px-4 py-2.5 rounded-lg h-[46px] disabled:opacity-60 disabled:cursor-not-allowed;
}

.btn-disable {
    @apply bg-gray-200 px-4 py-2.5 rounded-lg hover:cursor-not-allowed h-[46px];
}

/* Table */

.div-table {
    @apply w-full overflow-x-auto mb-4 block bg-white rounded-lg border;
}

.h-header {
    @apply h-[2.5rem];
}

table {
    @apply w-full;
}

.thead-tr {
    @apply h-10 border-b-2 bg-white font-semibold;
}

.tbody-tr {
    @apply bg-white border-b h-8 hover:bg-gray-50 transition-colors duration-150;
}

th {
    @apply px-4 py-3.5 text-sm border-b font-bold;
}

td {
    @apply max-h-fit h-8 px-4 py-2.5 text-sm border-b;
}

/* mat */

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    @apply !bg-white rounded-lg;
}

.mat-form-field {
    @apply !border-0 !h-fit;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    @apply !p-0 !min-h-fit !h-fit;
}

.mat-mdc-form-field-focus-overlay {
    @apply bg-primary-400 bg-opacity-0;
}

.mat-mdc-menu-panel{
    @apply !rounded-lg mt-2 !max-w-[360px];
}

.mat-mdc-select-panel {
    @apply !rounded-lg mt-2;
}

.mdc-text-field {
    padding: 0 0px !important;
}

.mdc-line-ripple::before,
.mdc-line-ripple::after {
    width: 0 !important;
}

.mat-mdc-form-field-bottom-align::before {
    @apply !hidden;
}

.mat-mdc-select-trigger {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.mat-mdc-menu-item:hover {
    @apply text-primary-400;
}

mat-select {
    @apply bg-white flex border border-gray-300 hover:border hover:border-gray-400 focus:border focus:border-gray-400 rounded-lg px-4 h-[46px] outline-none placeholder:text-sm;
}

.mdc-text-field--disabled.mdc-text-field--filled {
    @apply !bg-white;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    overflow-y: visible;
}

.mat-mdc-dialog-surface {
    border-radius: 0.75rem !important;
}

/* screen sm */
@media (min-width: 0px) {
    .mat-bottom-sheet-container.cdk-dialog-container {
        border-radius: 1rem 1rem 0 0 !important;
    }

    .mat-bottom-sheet-container-large {
        min-width: calc(100vw - 512px) !important;
        max-height: calc(100vh - 96px) !important;
    }
}

/* screen md */
@media (min-width: 1024px) {
    .mat-bottom-sheet-container.cdk-dialog-container {
        border-radius: 0 !important;
    }

    .mat-bottom-sheet-container-large {
        min-width: 0px !important;
        height: 100% !important;
        max-height: 100vh !important;
    }

    .cdk-global-overlay-wrapper {
        align-items: center !important;
    }
}

/* Dialog Animation */

.slide-in-right {
    @apply !h-full !max-w-full !w-auto !ml-auto;
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both !important;
    animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both !important;
}

@-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@media only screen {
    .component-slide {
        @apply block md:fixed md:right-0 md:top-0 bg-white md:h-screen overflow-hidden z-50 transform transition-all duration-500 delay-75 ease-in-out;
    }

    .before-position-slide {
        @apply w-96;
    }

    .after-position-slide {
        @apply w-0;
    }

    .component-slide-right {
        @apply fixed right-0 top-0 bg-white h-screen overflow-hidden z-50 transform transition-all duration-500 delay-75 ease-in-out;
    }

    .before-position-slide-right {
        @apply w-full md:w-96;
    }

    .after-position-slide-right {
        @apply w-0;
    }
}

@media only screen and (max-width: 767px) {
    .component-slide {
        @apply block absolute bottom-0 bg-white w-full overflow-hidden z-50 transform transition-all duration-300;
    }

    .before-position-slide {
        @apply h-screen;
    }

    .after-position-slide {
        @apply h-0;
    }
}

.mat-mdc-progress-bar {
    --mdc-linear-progress-track-color: rgba(201, 201, 201, 0.25);
}

.progress-success {
    --mdc-linear-progress-active-indicator-color: #84cc16;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

[type="radio"]:checked,
[type="checkbox"]:checked {
    @apply bg-yellow-500 border-yellow-500 bg-cover bg-no-repeat;
    background-position: 50%;
}

[type="radio"] {
    @apply appearance-none inline-block w-5 h-5 p-0 rounded-full bg-white border border-gray-300 text-current hover:shadow-md shadow-black align-middle select-none disabled:bg-gray-300 disabled:border-gray-500 disabled:shadow-none;
    -webkit-print-color-adjust: exact;
}

[type="radio"]:checked {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E");
}


.loader-page {
    position: relative;
    width: 48px;
    height: 48px;
    background: #f9781d;
    transform: rotateX(65deg) rotate(45deg);
    color: #3d2c21;
    animation: layers1 0.5s linear infinite alternate;
}

.loader-page:after {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(220, 220, 220, 0.7);
    animation: layerTr 0.5s linear infinite alternate;
}

@keyframes layers1 {
    0% {
        box-shadow: 0px 0px 0 0px;
    }

    90%,
    100% {
        box-shadow: 20px 20px 0 -4px;
    }
}

@keyframes layerTr {
    0% {
        transform: translate(0, 0) scale(1);
    }

    100% {
        transform: translate(-25px, -25px) scale(1);
    }
}

.dialog-xs {
    @apply w-screen max-w-full lg:max-w-lg min-h-[150px] max-h-[90vh] overflow-y-auto flex flex-col justify-between p-4 sm:p-6 relative;
}

.dialog-sm {
    @apply w-screen max-w-full lg:max-w-xl min-h-[150px] max-h-[90vh] overflow-y-auto flex flex-col justify-between p-4 sm:p-6 relative;
}


.dialog-md {
    @apply w-screen max-w-full lg:max-w-3xl min-h-[150px] max-h-[90vh] overflow-y-auto flex flex-col justify-between p-4 sm:p-6 relative;
}

.dialog-lg {
    @apply w-screen max-w-full xl:max-w-5xl min-h-[150px] max-h-[90vh] overflow-y-auto flex flex-col justify-between p-4 sm:p-6 relative;
}

.label-light {
    @apply relative text-white bg-secondary-400 bg-gradient-to-bl from-secondary-300 to-transparent px-3 py-1 w-fit min-w-max rounded-full !text-sm;
}

.label-primary {
    @apply relative text-white bg-primary-400 bg-gradient-to-bl from-primary-300 to-transparent px-3 py-1 w-fit min-w-max rounded-full !text-sm;
}


.slide-top {
    -webkit-animation: slide-top .5s cubic-bezier(.25, .46, .45, .94) both;
    animation: slide-top .5s cubic-bezier(.25, .46, .45, .94) both
}

@-webkit-keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    100% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px)
    }
}

@keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }

    100% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px)
    }
}
[type="checkbox"] {
    @apply appearance-none inline-block w-5 h-5 p-0 rounded bg-white border border-gray-300 text-current hover:shadow-md shadow-black align-middle select-none disabled:bg-gray-300 disabled:border-gray-500 disabled:shadow-none;
    -webkit-print-color-adjust: exact;
}
[type="checkbox"]:checked {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23000' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
}

.label-primary {
    @apply relative text-black bg-primary-400 px-3 py-1 w-fit min-w-max rounded-md !text-sm;
}

.label-light {
    @apply relative text-gray-500 bg-gray-200 px-3 py-1 w-fit min-w-max rounded-md !text-sm;
}

.mat-datepicker-content .mat-calendar {
    /* width: 296px;
    height: 367px !important; */
    @apply !min-h-[367px] !min-w-[296px];
}

.mat-calendar-table-header {
    @apply !text-xs;
}

.mat-form-field-wrapper {
    @apply pb-0;
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
    @apply text-secondary-800;
    margin-top: -8px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    border-radius: 0.5rem;
    --tw-bg-opacity: 1 !important;
    @apply !bg-gray-100
}

.loader-processing {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 10px solid;
    border-color: #4b566440 #4b566455 #4b566470 #4b566495;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 